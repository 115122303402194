/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .smallbar {
  padding: 0;
}
.altai-theme-admin .infocenterlist {
  overflow: hidden;
  color: white;
}
.altai-theme-admin .infocenterlist .md-center {
  background-color: transparent;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 12px;
  text-transform: none;
  font-weight: 800;
  letter-spacing: 0.1em;
  margin: 10px;
}
.altai-theme-admin .infocenterlist .contenttypeletterpill {
  display: inline;
  border-radius: 12px;
  padding: 4px 10px 4px 10px;
  color: #f9f9f9;
}
.altai-theme-admin .infocenterlist .notificationcenterbutton {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 5px;
}
.altai-theme-admin .infocenterlist .commentpill {
  color: #fff;
  display: inline;
  min-width: 45px;
  text-align: center;
  border-radius: 12px;
  padding: 4px 10px 4px 10px;
  background-color: transparent;
  white-space: nowrap;
}
.altai-theme-admin .infocenterlist .commentpill.interesting {
  background-color: #ff003e;
}
.altai-theme-admin .infocenterlist .infocenterrow {
  height: 40px;
  font-size: 10px;
}
.altai-theme-admin .infocenterlist .infocenterrow .field-contenttype {
  flex: 1;
}
.altai-theme-admin .infocenterlist .infocenterrow .field-taskname {
  flex: 4;
}
.altai-theme-admin .infocenterlist .infocenterrow .field-contentitemname {
  flex: 4;
}
.altai-theme-admin .infocenterlist .infocenterrow .field-date {
  flex: 3;
}
.altai-theme-admin .infocenterlist .infocenterrow .field-commentpill {
  flex: 2;
  white-space: nowrap;
}
.altai-theme-admin .infocenterlist .infocenterfield {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.altai-theme-admin .infocenter .infocentericon {
  width: 40px;
  display: flex;
  background-color: transparent;
  height: 40px;
  /* padding: 10px; */
  flex: 1;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 10px;
}
.altai-theme-admin .infocenter .iconcounter {
  position: absolute;
  top: 4px;
  margin-left: 11px;
  background-color: #ff003e;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
